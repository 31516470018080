import { createGlobalStyle } from 'styled-components'

import AeroportWoff from '../fonts/subset-Aeroport.woff'
import AeroportWoff2 from '../fonts/subset-Aeroport.woff2'
import AeroportBoldWoff from '../fonts/subset-Aeroport-Bold.woff'
import AeroportBoldWoff2 from '../fonts/subset-Aeroport-Bold.woff2'

import SuisseIntlRegularWoff from '../fonts/subset-SuisseIntl-Regular.woff'
import SuisseIntlRegularWoff2 from '../fonts/subset-SuisseIntl-Regular.woff2'
import SuisseIntlMediumWoff from '../fonts/subset-SuisseIntl-Medium.woff'
import SuisseIntlMediumWoff2 from '../fonts/subset-SuisseIntl-Medium.woff2'
import SuisseIntlBoldWoff from '../fonts/subset-SuisseIntl-Bold.woff'
import SuisseIntlBoldWoff2 from '../fonts/subset-SuisseIntl-Bold.woff2'

import AkzidenzGroteskProWoff from '../fonts/subset-AkzidenzGroteskPro-BoldEx.woff'
import AkzidenzGroteskProWoff2 from '../fonts/subset-AkzidenzGroteskPro-BoldEx.woff2'

export const theme = {
  fonts: {
    main: 'Suisse Intl, -apple-system, sans-serif, Arial',
    headers: 'Aeroport, -apple-system, sans-serif, Arial'
  },
  colors: {
    primary: 'hsl(247, 67%, 10%)',
    violet: 'hsl(286, 61%, 39%)',
    violetLight: 'hsl(286, 61%, 69%)',
    caption: 'hsl(219, 30%, 26%)',
    active: 'hsl(344, 75%, 47%)',
    background: 'hsl(216, 19%, 95%)',
    onBackground: 'hsl(219, 25%, 89%)'
  },
  breakpoints: {
    mobile: 'only screen and (max-width: 576px)',
    tablet: 'only screen and (max-width: 768px)',
    tabletPlus: 'only screen and (max-width: 1024px)',
    notebook: 'only screen and (max-width: 1200px)'
  },
  animations: {
    linksColor: 'color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)'
  },
  shadows: {}
}

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Suisse Intl';
        src: local('Suisse Intl Regular'), 
            local('SuisseIntl-Regular'), 
            url(${SuisseIntlRegularWoff2}) format('woff2'),
            url(${SuisseIntlRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Suisse Intl';
        src: local('Suisse Intl Medium'), 
            local('SuisseIntl-Medium'), 
            url(${SuisseIntlMediumWoff2}) format('woff2'),
            url(${SuisseIntlMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Suisse Intl';
        src: local('Suisse Intl Bold'), 
            local('SuisseIntl-Bold'),
            url(${SuisseIntlBoldWoff2}) format('woff2'),
            url(${SuisseIntlBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Aeroport';
        src: url(${AeroportWoff2}) format('woff2'),
            url(${AeroportWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Aeroport';
        src: url(${AeroportBoldWoff2}) format('woff2'),
            url(${AeroportBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Akzidenz-Grotesk Pro';
        src: url(${AkzidenzGroteskProWoff2}) format('woff2'),
            url(${AkzidenzGroteskProWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }
    body,html {
        background-color: ${({ theme }) => theme.colors.background};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        color: ${({ theme }) => theme.colors.primary};
        font-family: ${({ theme }) => theme.fonts.main}!important;
        font-size: 16px;
        line-height: 16px;
    }
    body {
        background-color: ${({ theme }) => theme.colors.background};
        pointer-events: inherit!important;
    }
    


    //Open Modal
    body.openModal {
        overflow: hidden;
    }
    //Swal
    .swal2-popup {
        border-radius: 1rem!important;
    }
    .swal2-title {
        font-size: 1.5rem!important;
        font-family: ${({ theme }) => theme.fonts.headers}!important;
        color: ${({ theme }) => theme.colors.caption}!important;
        font-weight: normal!important;
    }
    .swal2-html-container {
        font-size: 1rem!important;
        color: ${({ theme }) => theme.colors.caption}!important;
    }
    .swal2-styled.swal2-confirm {
        border-radius: 100px!important;
        padding: 1.15rem 1.5rem!important;
        font-size: 1.15rem!important;
        line-height: 1.5rem!important;
    }
    .swal2-no-war {
        display: none!important;
    }
    a {
        color: ${({ theme }) => theme.colors.violet};
        text-decoration: none;
        transition: ${({ theme }) => theme.animations.linksColor};
    }
    a:hover {
        color: ${({ theme }) => theme.colors.active};
        text-decoration: underline;
    }

    //Headers
    h1 {
        font-family: ${({ theme }) => theme.fonts.headers};
        font-size: 3.75rem;
        font-weight: bold;
        line-height: 100%;
    }
    h2 {
        font-family: ${({ theme }) => theme.fonts.headers};
    }
    h3 {
        font-family: ${({ theme }) => theme.fonts.headers};
    }
    @media ${({ theme }) => theme.breakpoints.notebook} {
        h1 {
            font-size: 2rem;
            line-height: 125%;
        }
    }
    @media ${({ theme }) => theme.breakpoints.tablet} {
        [data-aos^='fade'][data-aos^='fade'] {
            opacity: 100% !important;
        }
        h1{
            font-size: 1.5rem;
            line-height: 125%;
        }
        h1 br {
            display: none;
        }
    }
    @media ${({ theme }) => theme.breakpoints.mobile} {
        [data-aos^='fade'][data-aos^='fade'] {
            opacity: 100% !important;
        }
        h1{
            font-size: 1.5rem;
            line-height: 125%;
        }
        h1 br {
            display: none;
        }
    }
    //Animations
    [data-aos='fade-up-ee'] {
        opacity: 0;
        transition-property: transform, opacity;
        -webkit-transform: translateY(70px);
        transform: translateY(70px);
    }
    [data-aos='fade-left-ee'] {
        -webkit-transform: translate3d(70px, 0, 0);
        transform: translate3d(70px, 0, 0);
    }
    [data-aos='fade-right-ee'] {
        -webkit-transform: translate3d(-70px, 0, 0);
        transform: translate3d(-70px, 0, 0);
    }
    [data-aos='fade-up-right-ee'] {
        -webkit-transform: translate3d(-70px, 70px, 0);
        transform: translate3d(-70px, 70px, 0);
    }
    [data-aos][data-aos][data-aos-easing="cubic-bezier"],body[data-aos-easing="cubic-bezier"] [data-aos] {
        transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1.3);
    }
    
    //Forms
    .blockModalForm {
        top: 0;
        left: 0;
        background: rgba(12, 8, 41, 0.65);
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;
        padding: 5rem 0;
    }
    .blockModalForm .wrapper {
        max-width: 1200px;
        margin: auto;
        position: relative;
        background: #fff;
        border-radius: 1rem;
        width: 90%;
        padding: 5rem 0;
    }
    .blockModalForm h1 {
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 400;
        margin: 0 0 0.5rem;
    }
    .blockModalForm p {
        font-size: 1.15rem;
        line-height: 1.5rem;
        margin-bottom: 2.5rem;
    }
    .Form > div {
        position: relative;
    }
    .blockModalForm .hideForm {
        display: none
    }
    //Forms /Input
    .label {
        margin-bottom: 0.5rem;
    }
    .input {
        line-height: 1.5rem;
        color: ${({ theme }) => theme.colors.caption};
        border: 1px solid #dce1ea;
        border-radius: 0.5rem;
        padding: 1.25rem;
        display: block;
        width: 100%;
        margin-bottom: 1.75rem;
        outline: 0;
    }
    .input.grey {
        background-color: ${({ theme }) => theme.colors.background};
    }
    .input:focus {
        box-shadow: 0 0 0 0.25rem rgba(134, 39, 162, 0.25);
    }
    .input.isInvalid {
        border-color: ${({ theme }) => theme.colors.active};
        background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12Z" stroke="%23D21E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M9.75 9.75L14.25 14.25" stroke="%23D21E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M14.25 9.75L9.75 14.25" stroke="%23D21E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
        background-position: right 1.25rem center;
        background-repeat: no-repeat;
    }
    .input.isInvalid:focus {
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
    }

    //Forms /Select
    .input.select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1.25rem center;
        background-size: 16px 16px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    //Forms /Switch
    .form-check-group {
        margin-bottom: 1.25rem;
    }
    .form-check-group .header {
        margin-bottom: 1rem;
        font-weight: 500;
    }
    .form-check {
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5em;
        margin-bottom: 0.5rem;
    }
    .form-switch {
        padding-left: 2.5em;
    }
    .form-check .form-check-input {
        float: left;
    }
    .form-check label {
        line-height: 1.5rem;
    }
    .form-check-input {
        width: 1em;
        height: 1em;
        margin-top: .25em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid rgba(0,0,0,.25);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
    .form-check-input:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
    .form-switch .form-check-input {
        width: 2em;
        margin-left: -2.5em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
        background-position: left center;
        border-radius: 2em;
        transition: background-position .15s ease-in-out;
    }
    
    .form-switch .form-check-input:checked {
        background-position: right center;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
    //Forms /Checkbox
    .checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    .checkbox + label::before {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid ${({ theme }) => theme.colors.caption};
        border-radius: 0.5rem;
        margin-right: 0.75rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    .checkbox.isInvalid + label::before {
        border-color: ${({ theme }) => theme.colors.active};
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
    }
    .checkbox:checked + label::before {
        border-color: ${({ theme }) => theme.colors.violet};
        background-color: ${({ theme }) => theme.colors.violet};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    .checkbox:not(:disabled):not(:checked) + label:hover::before {
        border-color: ${({ theme }) => theme.colors.violet};
    }
    //Forms /Misc
    .blockModalForm .loading, .loadingAuth{
        position: absolute !important;
        z-index: 999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .blockModalForm .loading img, .Form .loading img{
        width: 4rem;
        height: 4rem;
    }
    .error {
        position: absolute;
        bottom: 0.5rem;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.active};
    }
    .privacyAgreement {
        padding-bottom: 1.75rem;
    }
    
    
    .blockModalForm .buttonAlign, .Form .buttonAlign{
        text-align: right;
    }
    @media ${({ theme }) => theme.breakpoints.mobile} {
        .blockModalForm {
            padding: 2rem 0;
        }
        .blockModalForm .wrapper {
            padding: 2rem 0;
        }
        .blockModalForm h1 {
            font-size: 1.75rem;
        }
        .input {
            line-height: 1.5rem;
            color: ${({ theme }) => theme.colors.caption};
            border: 1px solid #dce1ea;
            border-radius: 0.5rem;
            padding: 0.5rem;
            display: block;
            width: 100%;
            margin-bottom: 1.75rem;
            outline: 0;
        }
        .input.isInvalid {
            background-position: right 0.5rem center;
        }
        .blockModalForm .buttonAlign {
            text-align: center;
        }
        //Animations
        [data-aos='fade-up-ee'] {
            opacity: 0;
            transition-property: transform, opacity;
            -webkit-transform: none;
            transform: none;
        }
        [data-aos='fade-left-ee'] {
            -webkit-transform: none;
            transform: none;
        }
        [data-aos='fade-right-ee'] {
            -webkit-transform: none;
            transform: none;
        }
        [data-aos='fade-up-right-ee'] {
            -webkit-transform: none;
            transform: none;
        }
        [data-aos][data-aos][data-aos-easing="cubic-bezier"],body[data-aos-easing="cubic-bezier"] [data-aos] {
            transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1.3);
        }
    }

`
