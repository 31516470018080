import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-reboot.min.css";

import React from "react";
import { navigate } from 'gatsby'
import { ThemeProvider } from "styled-components";
import { GlobalStyles, theme } from "./src/themes/theme";
import { updateToken, getUser } from './src/services/auth'

import "./src/styles/preloader.scss"

export const wrapPageElement = ({ element }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {element}
        </ThemeProvider>
    );
};

export const onClientEntry = async () => {
    window.onload = () => {
        const AOS = require("aos");
        AOS.init({
            disable: window.innerWidth < 1024,
            once: true,
            easing: "ease-in-sine",
        });
    };
    const checkUser = async () => {
        const result = await updateToken()
        if (result === false && getUser().user_id != null) {
            navigate('/login')
        }
    }
    await checkUser()
};