import axios from 'axios';
import { navigate } from 'gatsby';

export const isBrowser = () => typeof window !== 'undefined';
export const getUser = () => (isBrowser() && window.localStorage.getItem('authToken') ? JSON.parse(window.localStorage.getItem('authToken')) : {});
export const setUser = (data) => {
    window.localStorage.setItem('authToken', JSON.stringify(data));
};
export const handleLogin = async ({ login, password }) => {
    return axios
        .post(
            `${process.env.API_URL}/wp-json/jwt-auth/v1/token`,
            { username: login, password: password },
            {
                dataType: 'json',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
            }
        )
        .then(function (response) {
            if (response.data.active !== false) {
                const userdata = {
                    access_token: response.data.data.token,
                    user_id: response.data.data.id,
                    company: response.data.data.company,
                    FIO: response.data.data.FIO,
                    accountType: response.data.data.accountType,
                    email: response.data.data.email,
                    exp: response.data.exp,
                };
                setUser(userdata);
            }
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
};

export const updateToken = async () => {
    if (!isBrowser) return false;
    const user = getUser();
    if (user.user_id !== undefined) {
        return axios
            .post(
                `${process.env.API_URL}/wp-json/jwt-auth/v1/token/validate`,
                {},
                {
                    dataType: 'json',
                    headers: { Authorization: `Bearer ${user.access_token}` },
                }
            )
            .then(function (response) {
                if (response.data.success === true && response.data.active === true) {
                    const userdata = {
                        access_token: user.access_token,
                        user_id: response.data.user_id,
                        company: response.data.data.company,
                        FIO: response.data.data.FIO,
                        accountType: response.data.data.accountType,
                        email: response.data.data.email,
                        exp: user.exp,
                    };
                    setUser(userdata);
                    return true;
                } else {
                    setUser({});
                    return false;
                }
            })
            .catch(function (error) {
                setUser({});
                return false;
            });
    } else {
        return false;
    }
};
export const isLoggedIn = () => {
    if (!isBrowser) return false;
    const user = getUser();
    if (Date.now() >= user.exp * 1000) {
        setUser({});
        navigate('/login');
        return false;
    }
    return !!user.user_id;
};
export const logout = (callback) => {
    setUser({});
    callback();
};
